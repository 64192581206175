@import '../styles/variables';

.features-section {
  margin-bottom: 100px;

  .features {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @media all and (min-width: (500px)) {
      justify-content: start;
    }

    .feature {
      width: 45%;
      margin-right: 10px;
      margin-bottom: 10px;

      @media all and (min-width: (500px)) {
        width: 31%;
      }

      &--outer {
        position: relative;
        display: flex;
        cursor: pointer;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        -webkit-box-pack: center;
        justify-content: center;
        border-width: 1px;
        border-style: solid;
        border-color: $color--feature-border;
        border-image: initial;
        border-radius: 8px;
        transition: $transition--feature;

        &:hover {
          box-shadow: rgba(0, 0, 0, 0.1) 0 5px 15px -5px;
          transform: translateY(-4px);
          border-color: $color--feature-border__hover;
          opacity: 1;

          .feature--text {
            border-top-color: $color--feature-border__hover;

            .arrow {

              svg {
                > g > polygon {
                  fill: $color--feature-border__hover;
                }
              }
            }
          }

          .feature--image {
            .image {
              svg {
                &.docs-icon > g,
                &.previewer > path,
                &.other > path,
                &.metafields > path,
                &.priority > g > g,
                &.product > path {
                  fill: $color--feature__hover;
                }

                &.ideas-box > g,
                &.bug > g,
                &.feature > g,
                &.feature > g > g,
                &.feature > g > g > path,
                &.megaphone > g,
                &.priority > g,
                &.priority > g > path,
                &.sale > g {
                  stroke: $color--feature__hover;
                }

                &.sale > g {
                  &:last-child {
                    stroke: none;
                    fill: $color--feature__hover;
                  }
                }
              }
            }
          }
        }
      }

      &--image {
        position: relative;
        padding-top: 61%;

        .image {
          position: absolute;
          top: 23%;
          right: 23%;
          bottom: 23%;
          left: 23%;
          display: flex;
          -webkit-box-pack: center;
          justify-content: center;
          -webkit-box-align: center;
          align-items: center;

          svg {
            width: 80%;
            height: 80%;

            &.docs-icon > g,
            &.previewer > path,
            &.other > path,
            &.metafields > path,
            &.priority > g > g,
            &.product > path {
              fill: $color--feature;
              transition: $transition--feature;
            }

            &.ideas-box > g,
            &.bug > g,
            &.feature > g,
            &.feature > g > g,
            &.feature > g > g > path,
            &.megaphone > g,
            &.priority > g,
            &.priority > g > path,
            &.sale > g {
              stroke: $color--feature;
              transition: $transition--feature;
            }

            &.sale > g {
              &:last-child {
                stroke: none;
                fill: $color--feature;
                transition: $transition--feature;
              }
            }
          }

          img {
            max-width: 100%;
            max-height: 80%;
            width: 100%;
            height: auto;
          }
        }
      }

      &--text {
        display: flex;
        padding: 8px 16px;
        -webkit-box-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        align-items: center;
        border-top: 1px solid $color--feature-border;
        transition: $transition--feature;

        .text {
          width: 85%;

          h6 {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0;
            vertical-align: baseline;
            -webkit-font-smoothing: antialiased;
            margin: 0;
            padding: 0;
            border-width: 0;
            border-style: initial;
            border-color: initial;
            border-image: initial;
            color: $color--feature-h;
            font-family: sans-serif;
          }

          p {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: $color--feature-p;
            font-size: 14px;
          }
        }

        .arrow {
          width: 12px;
          height: 12px;
          display: inline-block;
          transform: rotate(45deg);
          vertical-align: baseline;
          -webkit-font-smoothing: antialiased;
          margin: 0;
          padding: 0;
          border-width: 0;
          border-style: initial;
          border-color: initial;
          border-image: initial;

          svg {
            display: block;
            overflow: hidden;

            > g > polygon {
              fill: $color--feature-arrow;
              transition: $transition--feature;
            }
          }
        }
      }
    }
  }
}