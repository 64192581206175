@import 'variables';

// element calculations
* {
  box-sizing: border-box;
  font-size: $font-size--default;
  color: $color--primary;
  font-family: sans-serif;
}

// stop scroll bar from skrinking page
html {
  overflow-y: scroll;
}

// fill the page
body {
  background: $color--lightblue;
  margin: 0;
}

main {
  height: 100%;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  font-family: sans-serif;
}

h1 {
  margin-bottom: 40px;
  font-family: sans-serif;
  font-weight: 700;
}

h2 {
  font-size: 20px;
  margin-bottom: 15px;
}

h3 {
  font-weight: 600;
  margin-bottom: 15px;
  font-size: 18px;
}

h6 {
  font-size: 16px;
}

p {
  margin: 0;
  //margin-bottom: 10px;
  line-height: 1.3;
}

a {
  text-decoration: none;
  color: $color--primary;
  &:hover {
    opacity: 0.5;
    text-decoration: none;
  }
  &:visited {
    text-decoration: none;
    color: $color--primary;
  }
  .disabled__link {
    cursor: not-allowed;
    opacity: 0.5;
    text-decoration: none;
  }
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

button {
  min-width: 80px;
  padding: 5px;
  background: $color--white;
  border: 1px solid $color--primary;
  color: $color--primary;
  cursor: pointer;
  transition: $transition--all;
  &.big {
    padding: 10px;
    width: 100px;
  }
  &.curved {
    border-radius: 6px;
  }
  &.dark {
    color: $color--white;
    background: $color--primary;
    &:hover {
      background-color: $color--brown;
    }
  }
  &:hover {
    background: $color--primary;
    color: $color--white;
  }
  &.disabled,
  &:disabled {
    cursor: not-allowed;
    opacity: .5;
  }
  &.red {
    border-color: $color--red;
    color: $color--red;
    &:hover {
      background: $color--red;
      color: $color--white;
    }
  }
  &.bin {
    min-width: auto;
    margin-left: 10px;
    &:hover > svg > g {
      fill: $color--red;
    }
    & > svg {
      width: 15px;
      height: auto;
    }
  }
  &.submit {
    background: $color--green;
    border-color: $color--green;
    color: $color--white;
    &:hover {
      opacity: .5;
    }
  }
  &.reset {
    background: $color--red;
    border-color: $color--red;
    color: $color--white;
    &:hover {
      opacity: .5;
    }
  }
}

.text-input {
  border: 1px solid $color--primary;
  min-width: 200px;
  height: 30px;
  width: 80%;
}

.content-container {
  height: 100%;
  min-height: calc(100vh - 50px);
  padding-top: 50px;
  margin: 0;
  background: $color--white;
}

.appLayout {
  width: 100%;
  margin-top: 50px;
  background: $color--lightblue;
  &--metafields {

  }
}

.content {
  min-height: calc(100vh - 90px);
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  .s, .m, .l {
    width: 100%;
  }
  .s {
    max-width: 400px;
  }
  .m {
    max-width: 600px;
  }
  .l {
    max-width: 900px;
  }
  .f {
    max-width: 100%;
    width: 100%;
  }
}

.intro  {
  h1.obvious {
    margin: 0 0 100px;
    padding: 80px 0 100px;
    border-bottom: 1px solid rgb(175, 175, 175);
  }
}