@import "variables";

.field-group {
  margin-bottom: 20px;

  label,
  input {
    width: 100%;
  }

  label {
  }

  input {
    margin-top: 5px;
    border: 1px solid $color--primary;
    border-radius: 0;
    padding: 5px;

    &:focus {
      outline: none;
      border: 1px solid blue;
      border-radius: 0;
      box-shadow: none;
      -moz-outline-radius: 0;
    }
  }

  input[type="checkbox"] {
    width: auto;
    margin-left: 10px;
  }
}