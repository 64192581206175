@import "../styles/variables.scss";

footer {
  padding: 10px;
  > div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;
  }

  .copyright {
    font-size: 0.875em;
    text-align: center;
  }
}