@import "../styles/variables";

.notification-wrapper {
  top: auto;
  bottom: 20px;
  position: fixed;
  left: 20px;
  background-color: transparent;
  max-height: calc(100% - 60px);
  max-width: calc(100% - 60px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 10000;
  @media all and (min-width: 600px) {
    max-width: 600px;
  }
}

.notification {
  display: block;
  position: relative;
  background-color: $color--orange;
  padding: 10px 20px;
  pointer-events: auto;
  border-radius: 3px;
  margin-bottom: 10px;
  transition: height 500ms ease-in-out, margin-bottom 500ms ease-in-out, padding 500ms ease-in-out;
  overflow: hidden;
  cursor: pointer;
  a {
    color: $color--white;
    font-weight: 400;
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
  h2 {
    font-size: 1em;
    margin-bottom: 8px;
    font-weight: 600;
    color: $color--white;
  }
  p {
    margin: 0;
    line-height: 1.3;
    font-size: 0.875em;
    color: $color--white;
    //margin-bottom: 8px;  // only used if we have a done button
  }
  .button-wrapper {
    text-align: right;
  }
  button {
    color: white;
    background: darkred;
    border-color: darkred;
    width: auto;
    padding: 6px 12px;
  }
  &.success {
    background-color: $color--green;
  }
  &.error {
    background-color: $color--red;
  }
  .close {
    width: 15px;
    height: 15px;
    position: absolute;
    right: 1px;
    top: 1px;
    overflow: hidden;
    text-indent: 100%;
    cursor: pointer;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    &:hover,
    &:focus {
      outline: none;
    }
    &::before,
    &::after {
      content: '';
      position: absolute;
      width: 1px;
      height: 60%;
      top: 50%;
      left: 50%;
      background: $color--white;
    }
    &:hover {
      &::before,
      &::after {
        background: $color--white;
      }
    }
    &::before {
      -webkit-transform: translate(-50%,-50%) rotate(45deg);
      transform: translate(-50%,-50%) rotate(45deg);
    }
    &::after {
      -webkit-transform: translate(-50%,-50%) rotate(-45deg);
      transform: translate(-50%,-50%) rotate(-45deg);
    }
  }
}