@import '../styles/variables';

header {
  position: fixed;
  top: 0;
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  z-index: 1000;
  padding: 0 10px;
  background: $color--white;

  nav {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;
    height: 100%;
    min-width: 100px;

    a {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 3px 20px 0;
      color: $color--darkgrey;

      &:visited {
        color: $color--darkgrey;
      }

      &:hover {
        padding-top: 0;
        border-top: 3px solid $color--brown;
        opacity: 1;
        color: $color--primary;
      }

      &.active {
        padding-top: 0;
        border-top: 3px solid $color--brown;
        opacity: 1;
        color: $color--primary;
      }

      &:first-child {
        margin-left: 0;
      }
    }
  }

  .logo-container {
    width: 150px;
    display: none;
    @media all and (min-width: 700px) {
      display: flex;
    }

    a {
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      &:hover {}
    }

    .logo {
      width: 100%;
    }
  }

  .button-container {
    width: 150px;
    text-align: right;
  }

  .options-container {
    width: 110px;
    @media all and (min-width: 700px) {
      width: 150px;
    }
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-content: center;
    align-items: center;
    .option {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      border-radius: 50%;
      font-weight: 600;
      height: 30px;
      width: 30px;
      margin-left: 8px;
      cursor: pointer;
      transition: box-shadow 300ms ease-in-out;
      font-size: 0.875em;
      &#stores-button {
        text-transform: uppercase;
        margin-left: 0;
        height: 30px;
        width: 70px;
        padding: 0 10px;
        border-radius: 5px;
      }
      &:hover {
        box-shadow: 0 0 6px 2px $color--lightgrey;
      }
      &.active {
        box-shadow: 0 0 6px 2px $color--lightgrey;
      }
    }
    .stores-window {
      position: absolute;
      background: transparent;
      top: 40px;
      right: 48px;
      @media all and (min-width: 700px) {
        top: 40px;
        right: 48px;
      }
      .store {
        height: 30px;
        width: 70px;
        font-weight: 600;
        background: $color--white;
        border-radius: 5px;
        box-shadow: 0 0 6px 2px $color--lightgrey;
        margin: 5px 0;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        cursor: pointer;
        font-size: 0.875em;
        &:hover {
          background: $color--primary;
          color: $color--white;
        }
      }
    }
    .settings-window {
      margin: 5px 0;
      top: 40px;
      background: $color--white;
      border-radius: 5px;
      box-shadow: 0 0 6px 2px $color--lightgrey;
      display: flex;
      flex-direction: column;
      position: absolute;
      width: 150px;
      .setting {
        padding: 10px;
        width: 100%;
        border-bottom: 1px solid $color--lightgrey;
        cursor: pointer;
        font-size: 0.875em;
        //font-weight: 600;
        &:first-child {
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
        }
        &:last-child {
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
          border-bottom: none;
        }
        &:hover {
          background: $color--primary;
          color: $color--white;
        }
      }
    }
  }
}

.logo {
  width: 200px;
}