@import "mixins";

$font--default: sans-serif;
$font--button: sans-serif;
$font--brand: sans-serif;

$font-size--default: 16px;

$color--primary: #392f2c;
$color--lightprimary: rgba(57, 47, 44, 0.5);
$color--pink: #f5eee9;
$color--lightpink: rgba(245, 238, 233, 0.5);
$color--red: red;
$color--orange: orange;
$color--white: white;
$color--lightblue: #F6F7FA;
$color--grey: #AFAFAF;
$color--lightgrey: #D7D7D7;
$color--darkgrey: #868586;
$color--brown: #5B5250;
$color--green: rgb(73, 184, 130);

$color--feature: $color--primary;
$color--feature__hover: rgb(73, 184, 130);
$color--feature-h: $color--primary;
$color--feature-p: $color--brown;
$color--feature-border: rgb(175, 175, 175);
$color--feature-border__hover: rgb(73, 184, 130);
$color--feature-arrow: $color--primary;

$transition--feature: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0s, box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0s, border-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0s, stroke 150ms cubic-bezier(0.4, 0, 0.2, 1) 0s, fill 150ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
$transition--all: all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0s;

$height-without-header-footer: calc(100vh - 50px - 36px);